import Layout from '@/layout/LayoutIndex.vue'

const routes = [
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/tracking',
    children: [
      {
        path: 'tracking',
        component: () => import('@/views/pc/tracking/index'),
        name: 'Tracking'
      },
      {
        path: 'service',
        component: () => import('@/views/pc/service/index'),
        name: 'Service'
      },
      {
        path: 'news',
        component: () => import('@/views/pc/news/index'),
        name: 'News'
      },
      {
        path: 'newsDetail',
        component: () => import('@/views/pc/news/detail'),
        name: 'NewsDetail'
      },
      {
        path: 'support',
        component: () => import('@/views/pc/support/index'),
        name: 'Support'
      },
      {
        path: 'about',
        component: () => import('@/views/pc/about/index'),
        name: 'About'
      },
      {
        path: 'contact',
        component: () => import('@/views/pc/contact/index'),
        name: 'Contact'
      },
      {
        path: 'quary',
        component: () => import('@/views/pc/tracking/quary'),
        name: 'Quary'
      }
    ]
  },
  {
    path: '/quaryMobile',
    component: () => import('@/views/pc/tracking/quaryMobile'),
    name: 'QuaryMobile'
  },

  {
    path: '/:pathMatch(.*)',
    redirect: '/404',
    hidden: true
  }
]

export default routes
