import request from '@/utils/request'

// 获取通用轮播图
export function getBannerList () {
  return request({
    url: '/api/home/banner',
    method: 'get'
  })
}

// 获取顶图
export function getTopGraph () {
  return request({
    url: '/api/home/top_graph',
    method: 'get'
  })
}

// 获取语言包
export function getLanguage (type) {
  return request({
    url: `/api/home/language?type=${type}`,
    method: 'get'
  })
}

// 获取首页新闻
export function getHomeNews () {
  return request({
    url: '/api/home/news',
    method: 'get'
  })
}

// 获取服务
export function getHomeServices () {
  return request({
    url: '/api/home/services',
    method: 'get'
  })
}

// 新闻列表
export function getNewsLists (data) {
  return request({
    url: '/api/news/lists',
    method: 'get',
    params: data
  })
}

// 服务列表
export function getServicesLists (data) {
  return request({
    url: '/api/services/lists',
    method: 'get',
    params: data
  })
}

// 新闻详情
export function getNewsDetail (data) {
  return request({
    url: '/api/news/detail',
    method: 'get',
    params: data
  })
}

// 主题列表
export function getSupportLists (data) {
  return request({
    url: '/api/support/lists',
    method: 'get',
    params: data
  })
}

// 关于我们
export function getHomeAbout () {
  return request({
    url: '/api/home/about',
    method: 'get'
  })
}

// 联系我们
export function getHomeContactus () {
  return request({
    url: '/api/home/contactus',
    method: 'get'
  })
}

// 查询订单号
export function getOrderSearch (orderNo) {
  return request({
    url: `/api/order/search?order_no=${orderNo}`,
    method: 'get'
  })
}

// 查询路由、物流信息
export function getQueryLogistics (data) {
  return request({
    url: `/api/order/query_logistics?order_no=${data.order_no}&mobile=${data.mobile}&language=${data.language}`,
    method: 'get'
  })
}

// 查询路由、物流信息
export function getOrderQuery (data) {
  return request({
    url: `/api/order/query?order_no=${data.order_no}`,
    method: 'get'
  })
}
