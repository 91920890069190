import {
  createRouter,
  createWebHistory
} from 'vue-router'
// pc端路由
import routerPC from './routerPc'
// 移动端路由
import routerMobile from './routerMobile'

// 判断浏览器是pc还是移动端
const isMobile = function () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routerPC
  // routes: isMobile() ? routerMobile : routerPC // 如果不需要分pc/移动端 只使用pc端路由即可
})

export default router
