import dayjs from 'dayjs'
// 空值判断
const isEmpty = (val) => {
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (!Object.keys(val).length) return true
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

// 处理媒体文件路径
const formatFilePath = (url, type) => {
  const host = process.env.VUE_APP_OBS_PREFIX
  const isComplete = url.startsWith('http')
  if (isComplete) {
    return url
  } else {
    const hasSlash = url.startsWith('/')
    return hasSlash ? host + url : host + '/' + url
  }
}

// 时间格式化
const formatDate = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  // 10位要补齐至13位
  let data = Number(date)
  if (data.toString().length === 10) {
    data = data * 1000
  }
  return dayjs(data).format(format)
}

export default {
  isEmpty,
  formatFilePath,
  formatDate
}
