import {
  defineStore
} from 'pinia'

import { getTopGraph, getLanguage } from '@/api/common'
export const configData = defineStore('config', {
  state: () => {
    return {
      topGraph: '',
      languageInfo: '',
      languageType:''
    }
  },
  actions: {
    async initConfig (type) {
      const { data } = await getTopGraph()
      this.topGraph = data
      const res = await getLanguage(type)
      this.languageInfo = res.data
      this.languageType = type
    }
  }
})
