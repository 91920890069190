import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-main"
};
import LayoutHead from './components/LayoutHead';
import LayoutFooter from './components/LayoutFooter';
import { configData } from '@/store/config';
export default {
  __name: 'LayoutIndex',

  setup(__props) {
    const config = configData();
    config.initConfig('en');
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock(_Fragment, null, [_unref(config).languageInfo ? (_openBlock(), _createBlock(_unref(LayoutHead), {
        key: 0
      })) : _createCommentVNode("", true), _createElementVNode("section", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [_createVNode(_Transition, {
          name: "fade",
          mode: "out-in"
        }, {
          default: _withCtx(() => [_createElementVNode("div", null, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))])]),
          _: 2
        }, 1024)]),
        _: 1
      })]), _createVNode(_unref(LayoutFooter))], 64);
    };
  }

};