import axios from 'axios'
// import { getToken } from '@/utils/auth'
import Qs from 'qs'
// import { useUserStore } from '@/store/userStore'
import router from '@/router'
import { ElMessage } from 'element-plus'

// 可以导入element plus 的弹出框代替alert进行交互操作

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 15 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (!(config.data instanceof FormData)) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
        config.data = Qs.stringify(config.data)
      }
    }
    // 此处可以执行处理添加token等逻辑
    // config.headers["Authorization"] = getToken();
    // const store = useUserStore()
    // if (store.token) {
    //   config.headers.token = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data // 根据接口返回参数自行处理

    if (res.code !== 1) {
      const isShowMessage = response.config.isShowMessage === undefined ? true : response.config.isShowMessage
      if (isShowMessage) {
        ElMessage({
          message: res.msg || '网络异常',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.message || '网络异常'))
    } else {
      return res
    }
  },
  error => {
    // const store = useUserStore()
    console.log('err' + error) // 出现异常的处理
    if (error.response.status === 401) {
      // 退出登录并返回到首页等操作
      // store.resetToken().then(() => {
      //   router.replace({ path: '/' })
      // })
    }
    ElMessage({
      message: error.response.data.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
