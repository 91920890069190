import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import '@/styles/index.scss'
// import wsConnection from '@/utils/webSocket' // import webSocket
import { VueClipboard } from '@soerenmartius/vue3-clipboard' // clipboard

import elementIcon from '@/plugins/svgicon'// 实现element-puls icon的办法
import installElementPlus from '@/plugins/element'// 实现element-puls icon的办法
import 'vant/es/toast/style' // unplugin-vue-components 无法自动引入对应的样式，因此需要手动引入样式
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

createApp(App).use(createPinia()).use(router).use(VueClipboard).use(installElementPlus).use(elementIcon).mount('#app')

// 全局挂载webSocket
// const app = createApp(App)
// app.config.globalProperties.$setWs = wsConnection
// wsConnection.initWebSocket()
